<template>
  <v-container
    id="account-settings"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- Default orgTitle value is UDA Consulting -->
    <base-v-component
      :organization="orgTitle"
      heading="Account Settings"
      link="guide"
    />

    <v-row>
      <!--=== Left Column ===-->
      <v-col
        v-if="isOraganization"
        cols="12"
        lg="6"
      >
        <!-- Account info -->
        <v-card
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            color="primary"
            dark
            flat
            dense
          >
            <v-icon left>
              mdi-account
            </v-icon>
            <v-toolbar-title>{{ $t('account-settings.account-info') }}</v-toolbar-title><v-spacer />

            <!-- Add NEW Project User button -->
            <v-btn
              color="warning"
              fab
              dark
              @click="dialogPassword = true"
            >
              <v-icon>mdi-account-edit</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text
            v-if="$store.state.bend.organization.currentStaff.length > 0"
            class="text-center"
          >
            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ this.$store.state.auth.user.username }}
            </h4>
            <h6
              v-for="(item, i) in $store.state.bend.organization.currentStaff[0].roles"
              :key="i"
              class="display-1 mb-1 grey--text"
            >
              {{ item.level }}
            </h6>

            <p class="display-1 grey--text">
              e-mail: {{ $store.state.bend.organization.currentStaff[0].email }}
            </p>

            <p class="font-weight-light grey--text" />
          </v-card-text>
          <v-card-actions
            v-if="isOwner() && !isVip()"
            class="pt-0"
          >
            <v-spacer />
            <v-btn
              color="error"
              class="mr-0"
              @click="dialogDelAccount = true"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              {{ $t('account-settings.delete-account') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <!-- Staff list (not visible to clients) -->
        <v-card
          v-if="isOraganization && !isClient()"
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            color="primary"
            dark
            flat
            dense
          >
            <v-icon left>
              mdi-account-group
            </v-icon>
            <v-toolbar-title>{{ $t('account-settings.staff-list') }}</v-toolbar-title>
            <v-spacer />

            <!-- Add NEW Project User button -->
            <v-btn
              color="warning"
              fab
              dark
              @click="dialogAddUser = true"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-spacer />
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
          </v-card-title>
          <!--
          <v-card-title>
            <v-row
              justify="center"
              class="mt-4"
            >
              <div
                v-for="(tools) in projectRoles"
                :key="tools.name"
              >
                <v-btn
                  color="primary"
                  small
                  text
                  @click="filterStaff(tools)"
                >
                  {{ tools.access }}
                </v-btn>
              </div>
            </v-row>
          </v-card-title>
          -->
          <v-card-text v-if="$store.state.bend.organization.allStaff.length > 0 && $store.state.bend.organization.currentStaff.length > 0">
            <v-data-table
              :headers="staffHeaders"
              :items="projectRoles"
              :search.sync="search"
              :sort-by="['access']"
              :sort-desc="[false]"
              :items-per-page="5"
              item-key="syncDate"
              multi-sort
            >
              <!-- Action buttons -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip
                  v-for="(a, j) in staffActions"
                  :key="j"
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      small
                      dark
                      icon
                      :disabled="item.roles[0].label !== 'observer'"
                      @click="setUserActions({ user: item, action: a.action })"
                    >
                      <v-icon
                        :color="a.color"
                        v-on="on"
                      >
                        {{ a.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ a.action }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text
            v-else
            class="text-center"
          >
            <h4 class="display-2 font-weight-light mb-3 black--text">
              There is no other staff.
            </h4>
          </v-card-text>
        </v-card>
      </v-col>

      <!--=== Right Column ===-->
      <v-col
        cols="12"
        lg="6"
      >
        <!-- Organization (is not visible to clients) -->
        <v-card
          v-if="!isClient()"
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            color="primary"
            dark
            flat
            dense
          >
            <v-icon left>
              mdi-domain
            </v-icon>
            <v-toolbar-title>{{ $t('account-settings.organization') }}</v-toolbar-title>
            <v-spacer />

            <!-- Organization add/edit button -->
            <v-btn
              color="warning"
              fab
              dark
              @click="dialogEditOrg = true"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item
            v-if="isOraganization"
            three-line
          >
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                {{ $store.state.bend.organization.title }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $store.state.bend.organization.body }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="grey"
            >
              <img
                :src="$store.state.bend.organization.image"
                :alt="$store.state.bend.organization.title"
              >
            </v-list-item-avatar>
          </v-list-item>
          <v-card-text
            v-else
            class="text-center"
          >
            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ $t('account-settings.organization-no') }}
            </h4>
            <h6
              class="display-1 mb-1 grey--text"
            >
              {{ $t('account-settings.organization-add') }}
            </h6>
            <p class="font-weight-light grey--text" />
          </v-card-text>
        </v-card>

        <!-- Subscriptions  -->
        <v-card
          v-if="!isClient()"
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            color="primary"
            dark
            flat
            dense
          >
            <v-icon left>
              mdi-briefcase-check
            </v-icon>
            <v-toolbar-title>{{ $t('payment.subscription') }}</v-toolbar-title>
            <v-spacer />

            <!-- Upgrade subscription -->
            <v-btn
              color="warning"
              fab
              dark
              :to="{ name: 'Subscription' }"
            >
              <v-icon>
                mdi-briefcase-plus
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text
            class="text-center"
          >
            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ $t(mySubsc.title) }}
            </h4>
            <h6 :class="`display-1 mb-1 ${isExpired ? 'red' : 'grey'}--text`">
              Projects: {{ subscDetails.projects }}
              Forms: {{ $t(subscDetails.forms) }} ({{ $t(`${isExpired ? 'payment.expired' : 'payment.expires'}`) + ': ' }}
              <timeago :datetime="mySubsc.end" />)
            </h6>
            <p class="font-weight-light grey--text" />
          </v-card-text>
        </v-card>

        <!-- Admin Settings info -->
        <v-card
          v-if="isUda()"
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            color="secondary"
            dark
            flat
            dense
          >
            <v-icon left>
              mdi-account
            </v-icon>
            <v-toolbar-title>{{ $t('account-settings.admin-settings') }}</v-toolbar-title>
          </v-toolbar>
          <!-- VIP Client table -->
          <v-card-text
            class="font-weight-light grey--text"
          >
            <h5
              class="display-1 mb-1 grey--text"
            >
              {{ $t('account-settings.vip-client') }}
            </h5>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('account-settings.user-name') }}
                    </th>
                    <th class="text-left">
                      {{ $t('account-settings.email') }}
                    </th>
                    <th class="text-left">
                      {{ $t('account-settings.last-access') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in vipClient"
                    :key="i"
                  >
                    <td>{{ item.name[0].value }}</td>
                    <td>{{ item.mail[0].value }}</td>
                    <td>
                      <timeago
                        :datetime="item.access[0].value"
                        :auto-update="60"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <!-- ODK Server list table -->
          <v-card-text
            class="font-weight-light grey--text"
          >
            <div
              class="display-1 mb-1 grey--text"
            >
              {{ $t('account-settings.odk-server') }}
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    color="secondary"
                    icon
                    small
                    dark
                    @click="dialogAddDefServer = true"
                  >
                    <v-icon v-on="on">
                      mdi-database-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('account-settings.add-odkserver') }}</span>
              </v-tooltip>
            </div>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('project-list.server-url') }}
                    </th>
                    <th class="text-left">
                      {{ $t('table-header.type') }}
                    </th>
                    <th class="text-left">
                      {{ $t('table-header.actions') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in odkServer"
                    :key="i"
                  >
                    <td>{{ item.name }}</td>
                    <td>
                      <v-icon
                        left
                        :color="item.color"
                      >
                        {{ item.icon }}
                      </v-icon>
                    </td>
                    <td
                      class="text-right"
                    >
                      <v-tooltip
                        bottom
                      >
                        <template #activator="{ on }">
                          <v-btn
                            small
                            icon
                            color="error"
                            dark
                            v-on="on"
                            @click="updOdkServer({ type: 'delete', server: item.name })"
                          >
                            <v-icon v-if="!item.default">
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('tooltip.delete') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-row class="px-4">
            <!-- First column -->
            <v-col
              cols="12"
              md="6"
            >
              <v-card-text class="grey--text">
                <v-checkbox
                  v-model="experimental"
                  :label="$t('account-settings.experimental')"
                  hide-details
                  dense
                  @click="updateTesterState()"
                />
              </v-card-text>
            </v-col>
            <!-- Second column -->
            <v-col
              cols="12"
              md="6"
            >
              <v-card-text class="grey--text pt-6">
                ({{ $t('common.version') }} {{ appVersion }})
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <!-- Application info -->
        <v-card
          v-if="!isUda()"
          class="mt-2 mr-2"
          outlined
        >
          <v-toolbar
            color="indigo"
            dark
            flat
            dense
          >
            <v-icon left>
              mdi-account
            </v-icon>
            <v-toolbar-title>{{ $t('account-settings.app-info') }}</v-toolbar-title><v-spacer />
          </v-toolbar>
          <v-card-text
            v-if="$store.state.bend.organization.currentStaff.length > 0"
            class="text-center"
          >
            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{ $t('oth.udacapi') }}
            </h4>
            <h6
              class="display-1 mb-1 grey--text"
            >
              {{ $t('common.version') }} {{ appVersion }}
            </h6>

            <p class="display-1 grey--text">
              {{ $t('account-settings.contactus-email') }}
            </p>

            <p class="font-weight-light grey--text" />
          </v-card-text>
        </v-card>

        <!-- Project Forms Card
        <v-card
          v-if="isOraganization"
          class="mt-2 mr-2"
          outlined
        >
          <v-card-text v-if="projects.length > 0">
            <v-col
              v-for="(prj, i) in projects"
              :key="i"
              class="px-0"
            >
              <v-col class="px-0">
                <v-card
                  class="ma-0 px-0"
                  outlined
                  color="grey lighten-5"
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title class="display-2 mb-1">
                        {{ prj.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Last changed
                        <timeago
                          :datetime="prj.changed"
                          :locale="$i18n.locale"
                          :auto-update="60"
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-btn
                      color="warning"
                      icon
                      large
                      @click="pSettings({ project: prj })"
                    >
                      <v-icon>
                        mdi-cog
                      </v-icon>
                    </v-btn>
                  </v-list-item>
                </v-card>
              </v-col>

              <v-card
                v-for="(form) in projectForms(i)"
                :key="form.id"
                flat
                class="my-0"
              >
                <v-row
                  :class="`pa-0 mx-2 project ${form.odksettings.phase}`"
                >
                  <v-col
                    cols="7"
                  >
                    <div class="caption grey--text">
                      Project title and version
                    </div>
                    <span>{{ form.title }}</span> <span class="grey--text">(v.{{ form.odksettings.version }})</span>
                  </v-col>
                  <v-col
                    v-if="form.odksettings.phase === 'active'"
                    cols="3"
                  >
                    <div class="caption grey--text">
                      Submission
                    </div>
                    <div>{{ form.submissions }}</div>
                  </v-col>
                  <v-col
                    v-if="form.odksettings.phase !== 'active'"
                    cols="3"
                  >
                    <div class="caption grey--text">
                      Form changed
                      <timeago
                        :datetime="form.changed"
                        :locale="$i18n.locale"
                        :auto-update="60"
                        class="caption grey--text"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="2"
                  >
                    <div class="float-right">
                      <v-chip
                        small
                        :class="`${form.odksettings.phase} white--text my-2 caption`"
                      >
                        {{ form.odksettings.phase }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
                <v-divider />
              </v-card>
            </v-col>
          </v-card-text>
          <v-card-text
            v-else
            class="text-center"
          >
            <h6 class="display-1 mb-1 grey--text">
              There is no project yet. Add one in 'Drafts' section
            </h6>
          </v-card-text>
        </v-card>
        -->
      </v-col>
    </v-row>

    <!-- Dialogs -->
    <v-row justify="center">
      <!-- New Staff -->
      <v-dialog
        v-model="dialogAddUser"
        persistent
        max-width="320"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('account-settings.new-staff') }}</span>
          </v-card-title>

          <v-card-text>
            <!-- Add NEW Project User button -->
            <validation-observer
              v-slot="{ handleSubmit }"
            >
              <form @submit.prevent="handleSubmit(registerUser)">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('register.user-name')"
                  rules="required"
                >
                  <v-text-field
                    v-model="username"
                    dense
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('register.user-name')"
                    prepend-icon="mdi-account"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-settings.user-type')"
                  rules="required"
                >
                  <v-select
                    v-model="selectedType"
                    :hint="selectedType.details"
                    color="secondary"
                    item-color="secondary"
                    :label="$t('account-settings.user-type')"
                    :items="staffTypes"
                    item-text="label"
                    item-value="type"
                    dense
                    persistent-hint
                    return-object
                    :error-messages="errors"
                    prepend-icon="mdi-key-variant"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-settings.email')"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('account-settings.email')"
                    prepend-icon="mdi-email"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-settings.password')"
                  rules="required"
                >
                  <v-text-field
                    v-model="password"
                    dense
                    class="mb-8"
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('account-settings.password')"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append.prevent="show = !show"
                  />
                </validation-provider>

                <v-row>
                  <v-spacer />
                  <v-btn
                    small
                    class="mr-3"
                    @click="dialogAddUser = false"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    class="mr-3"
                    type="submit"
                  >
                    {{ $t('common.register') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Delete User -->
      <v-dialog
        v-model="dialogDelete"
        persistent
        max-width="350"
      >
        <v-card>
          <v-card-title
            class="headline font-weight-regular primary white--text py-2"
          >
            {{ $t('common.heads-up') }}
          </v-card-title>
          <v-card-text>
            {{ $t('account-settings.del-user-notea') }} <strong>{{ selectedUser.name }}</strong>.<br>
            {{ $t('account-settings.del-user-noteb') }}  <br>
            {{ $t('account-settings.del-user-notec') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="dialogDelete = false"
            >
              <div>
                {{ $t('common.cancel') }}
              </div>
            </v-btn>
            <v-btn
              color="warning"
              text
              @click="deleteUser"
            >
              {{ $t('common.delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Edit User Type -->
      <v-dialog
        v-model="dialogEditUser"
        persistent
        max-width="320px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('account-settings.user-profile') }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div>{{ $t('account-settings.change-type') }} <strong>{{ selectedUser.name }} </strong></div>
                <v-select
                  v-model="selectedUser.type"
                  :hint="staffTypes.details"
                  :items="staffTypes"
                  item-text="label"
                  item-value="type"
                  label="User Type*"
                  required
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="editUTypeCancelled"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              color="warning"
              text
              @click="editUserType"
            >
              {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Add Organization -->
      <v-dialog
        v-model="dialogOrganization"
        persistent
        max-width="420px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('account-settings.organization-reg') }}</span>
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(postOrgActions)">
              <v-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-settings.organization-name')"
                  :rules="{ required: true, regex: /^[a-zA-Z0-9\s]+$/, max:25 }"
                >
                  <v-text-field
                    v-model="orgName"
                    outlined
                    counter="25"
                    dense
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('account-settings.organization-name')"
                    :hint="$t('hint.letter-numss')"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <v-textarea
                    v-model="orgDescription"
                    :error-messages="errors"
                    outlined
                    rows="2"
                    color="secondary"
                    label="Description"
                    placeholder="About organization"
                    hint="Add short description about organization"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="grey darken-1"
                  text
                  @click="dialogOrganization=false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  color="success"
                  text
                  type="submit"
                >
                  {{ $t('common.register') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <!-- Change Organization -->
      <v-dialog
        v-model="dialogEditOrg"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('account-settings.organization-settings') }}</span>
          </v-card-title>

          <v-card-text>
            <!-- Add NEW Project User button -->
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(changeOrgName)">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-settings.organization-name')"
                  :rules="{ required: true, regex: /^[a-zA-Z0-9\s]+$/, max:50 }"
                >
                  <v-text-field
                    v-model="newOrgName"
                    dense
                    counter="50"
                    class="mb-4"
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('account-settings.organization-name')"
                    :hint="$t('account-settings.change-orgname')"
                    persistent-hint
                    :readonly="!orgNameEdit"
                    :prepend-icon="formEditIcon"
                    @click:prepend="editName"
                  />
                </validation-provider>

                <v-row>
                  <v-spacer />
                  <v-btn
                    small
                    class="mr-3"
                    :disabled="loadingB"
                    @click="dialogEditOrg = false"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    class="mr-3"
                    :loading="loadingB"
                    type="submit"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Upgrade subscription -->
      <v-dialog
        v-model="dialogSubsc"
        persistent
        max-width="1300"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('account-settings.organization-settings') }}</span>
          </v-card-title>

          <payment />
        </v-card>
      </v-dialog>

      <!-- Change User's Password -->
      <v-dialog
        v-model="dialogPassword"
        persistent
        max-width="320"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('account-settings.change-password') }}</span>
          </v-card-title>

          <v-card-text>
            <validation-observer
              v-slot="{ handleSubmit }"
            >
              <form @submit.prevent="handleSubmit(changePassword)">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-settings.current-password')"
                  rules="required"
                >
                  <v-text-field
                    v-model="currentPass"
                    dense
                    class="mb-8"
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('account-settings.current-password')"
                    prepend-icon="mdi-lock-open-outline"
                    :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showCurrent ? 'text' : 'password'"
                    @click:append.prevent="showCurrent = !showCurrent"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('account-settings.new-password')"
                  rules="required"
                >
                  <v-text-field
                    v-model="newPass"
                    dense
                    class="mb-4"
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('account-settings.new-password')"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append.prevent="show = !show"
                  />
                </validation-provider>
                <v-row>
                  <small class="mb-4 grey--text">{{ $t('account-settings.logout-note') }}</small>
                </v-row>

                <v-row>
                  <v-spacer />
                  <v-btn
                    small
                    class="mr-3"
                    @click="dialogPassword = false"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    class="mr-3"
                    type="submit"
                  >
                    {{ $t('common.save') }}
                  </v-btn>
                </v-row>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Delete Account Dialog -->
      <v-dialog
        v-model="dialogDelAccount"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular primary white--text py-2">
            {{ $t('common.heads-up') }}
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(deleteAccount)">
              <v-card-text class="pb-1">
                {{ $t('account-settings.del-account-note') }}
                <validation-provider
                  v-slot="{ errors }"
                  name="Agree checkbox"
                  rules="required"
                >
                  <v-checkbox
                    v-model="agreecheckbox"
                    :error-messages="errors"
                    :label="$t('form-settings.sure')"
                    value="1"
                    type="checkbox"
                    required
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogDelAccount = false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  text
                  type="submit"
                >
                  {{ $t('common.delete') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>

      <!-- Add Default Server -->
      <v-dialog
        v-model="dialogAddDefServer"
        persistent
        max-width="420px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('project-list.server-url') }}</span>
          </v-card-title>
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(updOdkServer({ type: 'add' }))">
              <v-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('project-list.server-url')"
                  :rules="{ required: true, regex: /^(https:\/\/www\.|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }"
                >
                  <v-text-field
                    v-model="odkServerUrl"
                    outlined
                    dense
                    :error-messages="errors"
                    color="secondary"
                    :label="$t('project-list.server-url')"
                  />
                </validation-provider>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="grey darken-1"
                  text
                  @click="dialogAddDefServer=false"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                  color="success"
                  text
                  type="submit"
                >
                  {{ $t('common.register') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />
  </v-container>
</template>

<script>
  // Mixins
  // import Vue from 'vue'
  // import VueTimeago from 'vue-timeago'
  import organization from '@/mixins/organization'
  import webusers from '@/mixins/webusers'
  import projects from '@/mixins/projects'
  import userAccess from '@/mixins/user-access'
  import SnackBarCard from '@/components/SnackbarCard'
  import Payment from '@/views/home/sections/Payment'
  import payment from '@/mixins/payment'
  import { useSubscriptionStore } from '@/stores/SubscriptionStore'

  import { extend } from 'vee-validate'
  // eslint-disable-next-line camelcase
  import { alpha_num, regex } from 'vee-validate/dist/rules'

  const subscription = useSubscriptionStore()
  // Vue.use(VueTimeago, {
  //   name: 'Timeago', // Component name, `Timeago` by default
  //   locale: 'en-US', // Default locale
  //   locales: {
  //     pt: require('date-fns/locale/pt'),
  //     ru: require('date-fns/locale/ru'),
  //     tr: require('date-fns/locale/tr'),
  //   },
  // })

  // Vee Validate.
  extend('alpha_num', alpha_num)
  extend('regex', regex)
  // extend('positive', value => {
  //   return value >= 1 && value <= 99
  // })

  export default {
    name: 'AccountSettings',

    components: {
      SnackBarCard,
      Payment,
    },

    mixins: [
      organization,
      webusers,
      projects,
      userAccess,
      payment,
    ],

    data: () => ({
      orgName: '',
      orgDescription: '',
      odkServerUrl: '',

      dialogAddUser: false,
      dialogDelete: false,
      dialogPassword: false,
      dialogEditUser: false,
      dialogEditOrg: false,
      dialogSubsc: false,
      dialogOrganization: false,
      dialogDelAccount: false,
      dialogAddDefServer: false,

      agreecheckbox: false,

      username: '',
      email: '',
      password: '',

      currentPass: '',
      newPass: '',
      formEditIcon: 'mdi-pencil',
      newOrgName: '',
      orgNameEdit: false,
      loading: false,
      loadingB: false,
      vipClient: [],

      activerole: { code: 'none', label: 'None' },
      roles: [
        { code: 'none', label: 'None' },
        { code: 'viewer', label: 'Project Viewer' },
        { code: 'manager', label: 'Project Manager' },
      ],

      showCurrent: false,
      show: false,

      projects: [],

      // New snackbar notification
      snackBar: {
        type: 'success',
        mode: 'multi-line',
        direction: 'top center',
        timeout: 3000,
        title: '',
        text: '',
        visible: false,
      },

      search: undefined,

      staffActions: [
        { color: 'indigo', icon: 'mdi-pencil', action: 'Edit Type' },
        { color: 'red', icon: 'mdi-delete', action: 'Delete User' },
      ],

      staffTypes: [
        { type: 'staff', label: 'Staff', details: 'Staff can see all other staff and all projects' },
        { type: 'client', label: 'Client', details: 'Clients cannot see any other web users and can see only thier own projects' },
      ],

      selectedUser: { user: { type: '' } },
      selectedType: '',

      experimental: false,
      appVersion: '5.1.5',
    }),

    computed: {
      // Check if organization is entered
      isOraganization () {
        return ('id' in this.$store.state.bend.organization)
      },

      orgTitle () {
        if (this.$store.state.bend.organization !== undefined) {
          return this.$store.state.bend.organization.title
        } else {
          return 'UDACAPI'
        }
      },

      // orgTitle: {
      //   get () {
      //     if (this.$store.state.bend.organization !== undefined) {
      //       return this.$store.state.bend.organization.title
      //     } else {
      //       return 'UDACAPI'
      //     }
      //   },
      //   set (value) {
      //     this.newOrgName = value
      //   },
      // },

      // Map Staff and add access based on account owner and current user
      projectRoles () {
        const org = this.$store.state.bend.organization
        const cStaff = org.currentStaff[0]
        // const owneruid = org.owneruid
        const leftUsers = org.allStaff.filter(s => s.uuid !== cStaff.uuid)
        // item.roles[0].label === 'client' ? 'Account Owner' : item.type.charAt(0).toUpperCase() + item.type.slice(1)
        const result = leftUsers.map(user => ({
          ...user,
          access: (user.roles[0].label === 'client') ? 'Account Owner' : user.type.charAt(0).toUpperCase() + user.type.slice(1),
        }))
        // console.log(result)

        return result
      },

      staffHeaders () {
        return [
          { text: this.$t('table-header.name'), value: 'name' },
          { text: this.$t('table-header.type'), value: 'access' },
          // { text: 'Configure Client', value: 'configure' },
          { text: this.$t('table-header.actions'), value: 'actions', sortable: false, align: 'end' },
        ]
      },

      odkServer () {
        const arr = this.$store.state.ocpu.oldServer
        arr.reverse(JSON.parse(JSON.stringify(arr)))
        // console.log(oldServers)
        const servers = [{
          color: 'success',
          name: this.$store.state.ocpu.defServer,
          default: true,
          icon: 'mdi-database-check',
        }]
        // console.log(servers)
        for (var i = 0, len = arr.length; i < len; i++) {
          // console.log(oldServers[i])
          const obj = {}
          obj.color = 'warning'
          obj.name = arr[i]
          obj.default = false
          obj.icon = 'mdi-database-lock'
          servers.push(obj)
        }
        // console.log(servers)
        return servers
      },

      mySubsc () {
        // console.log(subscription.activeSubsc)
        return subscription.activeSubsc
      },

      isExpired () {
        // console.log(this.isSubscActive())
        return !this.isSubscActive()
      },

      subscDetails () {
        // console.log(subscription.pricing.filter(f => f.code === this.mySubsc.code))
        return subscription.pricing.filter(f => f.code === this.mySubsc.code)[0]
      },
    },

    mounted () {
      this.initiate()
      // FIXIT later delete this part. userd for old registered users. Also see in auth.js login()
      if (!this.$store.state.auth.user.userorg) this.updateUsersOrg()
      // console.log(this.$store.state.auth.user.userorg)
      this.experimental = this.$store.state.bend.organization.tester
      this.newOrgName = this.$store.state.bend.organization.title
      // console.log(this.projects)
      this.getVipClient()
      this.getSubscription()
      // this.odkServer()
    },

    methods: {
      async initiate () {
        // console.log(this.$store.state.bend.organization)
        if (this.isOraganization && !this.$store.state.bend.calls.projects) {
          // if projects was not called
          await this.getProjects()
          this.projects = this.usersProjects()
          // this.projects = this.$store.state.bend.organization.projects
        } else {
          this.projects = this.usersProjects()
          // this.projects = this.$store.state.bend.organization.projects
        }
        if (!this.$store.state.bend.calls.webuser) {
          // if web user was not called
          this.getWebuser()
        }
        // console.log(this.projects)
      },

      // FIXIT later delete this part. userd for old registered users
      updateUsersOrg () {
        const useruuid = this.$store.state.auth.user.uuid
        const body = {
          data: { type: 'node--organization', id: this.$store.state.bend.organization.id },
        }

        // Patch User and change user type
        return new Promise((resolve, reject) => {
          this.$httpBend
            .patch(`jsonapi/user/user/${useruuid}/relationships/field_organization`, body, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              if (response.status === 204) {
                this.$store.dispatch('auth/login')
                resolve(true)
              } else {
                // return resolve(false)
              }
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      },

      orgButton () {
        if (this.$store.state.bend.organization.title === undefined) {
          this.dialogOrganization = true
        } else {
          // console.log('aha')
        }
      },

      async postOrgActions () {
        const aha = await this.postOrganization()
        // console.log(aha)
        if (aha) {
          this.dialogOrganization = false
          this.getWebuser()
          // this.getProjects()
        }
      },

      filterStaff (prop) {
        // console.log(prop)
        // this.ftool = this.mtoolList.filter(item => {
        //   return item.id[0] === prop
        // })
      },

      // List of all forms of the project for rendering in template
      projectForms (prj) {
        const p = this.projects[prj]
        if ('forms' in p) {
          const active = p.forms.filter(f => f.odksettings.phase === 'active')
          const deployed = p.forms.filter(f => f.odksettings.phase === 'deployed')
          const drafts = p.forms.filter(f => f.odksettings.phase === 'draft')
          const result = active.concat(deployed, drafts)
          return result
        }
      },

      // Register new Web User
      async registerUser () {
        this.postWebuser()
        // this.getProjects()
      },

      // changePassword () {
      //   this.$store.dispatch('auth/changeUserPass', { current: this.currentPass, new: this.newPass })
      //     .then((aha) => {
      //       // console.log(aha)
      //       // this.$router.push({ name: 'Project Settings' })
      //     })
      // },

      deleteAccount () {
        const org = this.$store.state.bend.organization
        const noUsers = (org.allStaff.length === 1)
        const noProjects = (org.projects.length === 0)
        if (noUsers && noProjects) {
          this.deleteOrganization()
          this.$store.dispatch('auth/deleteUser').then((resp) => {
            // console.log(resp)
            this.$router.push({ name: 'Home' })
          })
        } else {
          if (!noUsers) {
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              text: this.$t('account-settings.del-staff-note'),
              visible: true,
            }
            this.dialogEditOrg = false
          } else if (!noProjects) {
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              text: this.$t('account-settings.del-project-note'),
              visible: true,
            }
            this.dialogEditOrg = false
          }
        }
        // console.log('change org name')
      },

      // check what user action was selected and activate that dialog
      setUserActions (prop) {
        this.selectedUser = prop.user
        // console.log(this.selectedUser)
        switch (prop.action) {
          case 'Edit Type':
            this.dialogEditUser = true
            break
          case 'Delete User':
            this.dialogDelete = true
            break

          default:
            break
        }
        // const org = this.$store.state.bend.organization
        // const index = org.allStaff.map(e => e.uuid).indexOf(prop.uuid)
        // console.log(index)
        // this.dialogDelete = true
      },

      deleteUser () {
        this.deleteWebuser(this.selectedUser)
      },

      editUTypeCancelled () {
        this.dialogEditUser = false
        this.getWebuser()
      },

      // Patch USER to change user type
      editUserType () {
        // console.log(this.selectedUser)

        const body = {
          data: {
            type: 'user--user',
            id: this.selectedUser.uuid,
            attributes: { field_type: this.selectedUser.type },
          },
        }
        // Patch User and change user type
        return new Promise((resolve, reject) => {
          this.$httpBend
            .patch('jsonapi/user/user/' + this.selectedUser.uuid, body, {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                'Content-Type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json',
              },
            })
            .then(response => {
              // console.log(response)
              if (response.status === 200) {
                // close 'New Staff' dialogue
                this.dialogEditUser = false
                // show information about success
                this.snackBar = {
                  type: 'success',
                  mode: 'multi-line',
                  text: `User ${this.username}'s type is now ${this.selectedUser.type}`,
                  visible: true,
                }
                const user = {
                  index: this.userIndex(this.selectedUser.uuid),
                  type: this.selectedUser.type,
                }
                this.$store.commit('bend/updateUserType', user)
                // .then(() => {
                //   this.$router.push({ name: 'Project Settings' })
                // })
              } else {
                // return resolve(false)
              }
            })
            .catch(error => {
              // console.log(error)
              return reject(error.message)
            })
        })
      },

      // Edit Organization's name
      editName () {
        this.orgNameEdit = !this.orgNameEdit
        if (this.orgNameEdit) {
          this.formEditIcon = 'mdi-restore'
        } else {
          this.formEditIcon = 'mdi-pencil'
        }
      },

      updOdkServer (prop) {
        const server = (prop.type === 'add') ? this.odkServerUrl : prop.server
        this.odkServerUrl = ''
        // console.log(prop)
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/upddefserver/json`, {
              srv: server,
              type: prop.type,
            })
            .then(response => {
              // console.log(response)
              if (response.status === 201) {
                this.$store.dispatch('ocpu/getDefServer')
                resolve(response)
              }
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
          this.dialogAddDefServer = false
        })
      },
    },
  }

</script>

<style lang="sass">
  .project.active
    border-left: 4px solid green
  .project.deployed
    border-left: 4px solid orange
  .project.draft
    border-left: 4px solid lightgrey

  .v-chip.active
    background: green !important
  .v-chip.deployed
    background: orange !important
  .v-chip.draft
    background: grey !important
</style>
